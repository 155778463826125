import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { api as axios } from '../api/base';

export const DicomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 65%;
  max-width: 65%;
  margin: 2rem auto;
  min-height: fit-content;

  & * {
    box-sizing: border-box;
  }
`;

export const DicomEntry = styled.div`
  display: grid;
  grid-template-areas: "title status time";
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: start;
`;

export const DicomHeader = styled.h2`
  color: #2D7687;
  margin: 1rem;
`;

export const EntryCell = styled.span`
  ${({ area }) => `grid-area: ${area};`}
  font-size: 0.9rem;
  display: flex;
  flex: 1;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.2);
`;

export const HeaderTitle = styled.h3`
  font-weight: 700;
`;

export const EntryTitle = styled.p`
  font-weight: 600;
`;

export const EntryStatus = styled.p`
  font-weight: 700;

  ${({ isProcessed, isProcessing }) => isProcessing
    ? `color: #DD0;`
    : isProcessed
      ? `color: #0D0;`
      : `color: #D00;`
  }
`;

export const EntryTime = styled.p`
  font-weight: 600;
`;

const DicomStatus = props => {
  const [failed, setFailed] = useState(false);
  const [dicomStatus, setDicomStatus] = useState({});
  const [manifestStatus, setManifestStatus] = useState({});
  const [dicomManifest, setDicomManifest] = useState({})

  const getDicomManifest = async _ => {
    await axios(`/radiologist/study/${props.studyID}/ki-manifest?key_images=true`)
      .then(response => {
        parseDicomManifest(response.data) })
  
      .catch(err => {
        if (!failed) {
          setFailed(true) };

        if (process.env.NODE_ENV !== 'production') {
          console.error('error getting dicom manifest:', err) } });
  }

  const parseDicomManifest = manifest => {
    const markup = manifest['novarad_mark-up'];

    let validImages = true;
    let validMarkup = markup.isProcessed;

    const markupData = {
      title: 'Dicom Markup',
      present: (markup.instanceNumbers.length > 0),
      timestamp: markup.lastUpdated,
      status: {
        isProcessed: markup.isProcessed,
        isProcessing: (markup.isProcessing !== '0') }};

    const manifestKeys = Object.keys(manifest)
      .filter(key => key !== 'novarad_mark-up');

    const parsedManifest = manifestKeys.reduce((newManifest, key) => {
      const {
        isProcessed,
        isProcessing,
        lastUpdated,
        instanceNumbers,
        processed } = manifest[key];

      if (!isProcessed) {
        validImages = false };

      const instanceList = instanceNumbers
        .map(item => ({ index: item, data: processed[item] }));

      const instanceTitle = key.split('_')
        .map(word =>
          word[0].toUpperCase() + word.slice(1))
        .join(' ');

      const data = {
        title: instanceTitle,
        count: instanceNumbers.length,
        timestamp: lastUpdated,
        status: { isProcessed, isProcessing: (isProcessing !== '0') },
        instances: instanceList };

      return { ...newManifest, [key]: data }}, {});

    let newDicomStatus = (!validMarkup || !validImages)
      ? 'missing'
        : 'valid';

    let newManifestStatus = { validMarkup, validImages };

    setDicomStatus(newDicomStatus);
    setManifestStatus(newManifestStatus);
    setDicomManifest({ ...parsedManifest, markupData });

  }

  const setDicomPoll = _ => {
    return setInterval(_ => {
      if (!manifestStatus.validMarkup || !manifestStatus.validImages) {
        getDicomManifest() };
    }, 10000);
  }

  const makeEntry = entry => (
    <DicomEntry key={ entry.title }>
      <EntryCell area='title'>

        <EntryTitle>
          { `${entry.title}` }
        </EntryTitle>    

      </EntryCell>
      <EntryCell area='status'>

        { entry.status.isProcessing
            ? (
              <EntryStatus isProcessing={ true }>
                Processing...
              </EntryStatus>)
            : (
              <EntryStatus isProcessed={ entry.status.isProcessed }>
                 { entry.status.isProcessed 
                  ? entry.count 
                    ? `${entry.count} instances` 
                    : ' Valid'
                  : ' Missing' }
              </EntryStatus>) }

      </EntryCell>
      <EntryCell area='time'>

        <EntryTime>
          { `${new Date(entry.timestamp)}`.split(' ').slice(0,5).join(' ') }
        </EntryTime>    

      </EntryCell>
    </DicomEntry> 
  )
  
  const mapEntries = manifest => {
    return Object.keys(manifest)
      .reduce((dicomEntries, entry) => {

        const newEntry = makeEntry(manifest[entry]);

        return [...dicomEntries, newEntry] }, []);
  }

  const dicomPoll = setDicomPoll();

  useEffect(_ => {
    if (!Object.keys(dicomManifest).length) {
      getDicomManifest() };

    if (failed) {
      clearInterval(dicomPoll) };

    return function cleanup() {
      if (dicomPoll) {
        clearInterval(dicomPoll) };
    }
  })

  

  return (
    <DicomWrapper>
      <DicomHeader>Dicom Status</DicomHeader>
      { Object.keys(dicomManifest).length
          ? (<>
              <DicomEntry>
                <EntryCell area='title'>
                  <HeaderTitle>Dicom Entry:</HeaderTitle>    
                </EntryCell>
                <EntryCell area='status'>
                  <HeaderTitle>Status:</HeaderTitle>
                </EntryCell>
                <EntryCell area='time'>
                  <HeaderTitle>Last Updated:</HeaderTitle>
                </EntryCell>
              </DicomEntry>
              { mapEntries(dicomManifest) }
            </>)
          : (
            <HeaderTitle>
              { failed 
                  ? `Error: No dicom information found.`
                  : `Loading...` }
              
            </HeaderTitle>)
      }
    </DicomWrapper>    
  )
}

export default DicomStatus;
